import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TsunagaruComponent } from './../common-component/tsunagaru/tsunagaru.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { AuthService } from '../auth/auth.service';
import { CommonCompanyService } from '../shared/common-company.service';
import { Constant } from '../constant';
import { PdfviewerDialogComponent } from '../dialog/pdfviewer-dialog/pdfviewer-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SliderComponent } from '../common-component/slider/slider.component';
import { MessageOnlyDialogComponent } from '../dialog/message-only-dialog/message-only-dialog.component';
import { sprintf } from 'sprintf-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-material',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    SliderComponent,
    TsunagaruComponent,
  ],
  templateUrl: './company-material.component.html',
  styleUrl: './company-material.component.css'
})
export class CompanyMaterialComponent {
  public readonly Constant = Constant;
  public companyCode = '';
  public materialCode = ''
  public companyInfo;
  public detailInfo;
  public otherList;
  public isLogin = false;
  public showSpinner = true;  // 初期表示スピナー
  public isDataGetting = false; // もっと見る用スピナー
  public tsunagaruData
  public materialShowSpinner = false;
  public pdfRealPage = 1;  // PDF表示ページ
  public pdfShowPage = 1;  // PDF表示ページ（最終ページを含む）
  public pdfTotalPage = 0;  // PDF全ページ数
  public pdfBtnDisabled = false
  public contentsListData
  public jobofferListData
  private apiPath = '/document';
  private showCnt = 6
  private offset = 0
  private progressRow = { code: null, endFlg: false };
  private pdfErrorFlg = false
  private pdfViewerWidth = 0
  private pdfViewerMaxHeight = 495
  private pdfSessionId
  private resetPdfView = true
  private isSP = false
  private subscriptionLike: Subscription;

  @ViewChildren('otherMaterial') otherMaterial: QueryList<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private common: CommonService,
    private commonCompany: CommonCompanyService,
    private dialog: MatDialog,
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
    this.materialCode = this.activatedRoute.snapshot.params['detailcode'];
  }

  ngOnInit(): void {
    // タイトル設定
    this.common.setTitle(Constant.tlContents);
    this.commonCompany.setCompanyHeaderPage(Constant.rpMaterial);
    // ログインチェック
    this.common.preCheck().then(res => {
      this.checkLogin();
    })
    .catch(err => {
      this.showSpinner = false
    });

    // 初期化
    this.initialize()
  }

  ngOnDestroy(): void {
    if (this.subscriptionLike) {
      this.subscriptionLike.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // URLが変更されたら再読み込みする
    this.activatedRoute.url.subscribe(params => {
      if (this.companyCode !== params[1].path || this.materialCode !== params[2].path) {
        this.detailInfo = null
        this.otherList = null
        if (this.companyCode !== params[1].path) {
          this.companyInfo = null
        }
        this.companyCode = params[1].path;
        this.materialCode = params[2].path;
        this.ngOnInit();
      }
    });

    // 他の資料高さ合わせ
    this.otherMaterial.changes.subscribe(data => {
      this.equalBoxHeight()
    });

    // いいね処理の終了
    this.subscriptionLike = this.commonCompany.sharedLikeExit$.subscribe(data => {
      this.commonCompany.progressViewDetach();
    });

    // スマホの場合PDFをリサイズしない
    this.isSP = this.common.isSP()
  }

  @HostListener('window:focus')
  onFocus(): void {
    // ログインチェック
    this.commonCompany.onFocusCheckLogin(this.isLogin).subscribe(res => {
      this.isLogin = res;
      if (this.tsunagaruData) {
        this.tsunagaruData.isLogin = this.isLogin
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    // PDFリサイズ　横幅拡大時に縦を伸ばす
    if (this.pdfTotalPage > 0 && this.pdfViewerWidth > 0 && !this.isSP) {
      this.resetPdfView = true
      const viewer = document.getElementsByClassName('pdf-viewer')  as HTMLCollectionOf<HTMLElement>;
      const canvas = document.querySelector('app-company-material .canvasWrapper canvas');
      if (viewer && viewer[0].clientHeight < this.pdfViewerMaxHeight && viewer[0].clientWidth>this.pdfViewerWidth) {
        let h = viewer[0].clientHeight * (viewer[0].clientWidth / this.pdfViewerWidth)
        if (h >= this.pdfViewerMaxHeight) {
          h = this.pdfViewerMaxHeight
        }
        viewer[0].style.height = h + 'px'
      }

      setTimeout(() => {
        if (canvas && viewer && canvas.clientHeight>0 && canvas.clientWidth != this.pdfViewerWidth) {
          viewer[0].style.height = canvas.clientHeight + 'px';
          this.pdfViewerWidth = canvas.clientWidth
        }
      }, 200)
    }

    // 他の資料高さ合わせ
    this.equalBoxHeight()
  }

  /* テンプレート起動 */
  // PDFダイアログ表示
  public onPdfDialog() {
    this.common.preCheck().then(res => {
      const dialogRef = this.dialog.open(PdfviewerDialogComponent, {
        width: Constant.dlWidthPdf,
        maxWidth: '99vw',
        autoFocus: false,
        panelClass: 'pdf-viewer-dialog',
        data: {
          url: this.detailInfo.document_url,
          blueFlg: true,
          realPage: this.pdfRealPage,
          showPage: this.pdfShowPage,
          companyId: this.companyInfo.id,
          isLogin: this.isLogin,
          documentId: this.detailInfo.id,
          sessionId: this.pdfSessionId,
          companyCode: this.companyCode,
        }
      });
      dialogRef.afterClosed().subscribe(page => {
        if (page) {
          this.pdfRealPage = page.real
          this.pdfShowPage = page.show
        }
      });
    })
    .catch(err => {});
  }

  // 他の資料もっと見る
  onNextData() {
    this.showCnt = 6
    this.common.preCheck().then(res => {
      this.isDataGetting = true;
      this.getMaterial();
    }, err => {});
  }

  // いいねボタン
  onLikeButton(target) {
    target.company_code = this.companyCode
    const type = Constant.contentsTypeMaterial
    this.commonCompany.progressViewAttach()

    this.common.preCheck().then(res => {
      if (target.good_flag === 1) {
        // いいね解除
        this.commonCompany.delGoodWithConfirm(target, this.isLogin, type)
        return;
      }

      if (!this.isLogin) {
        // 未ログインの場合、いいね実行
        this.commonCompany.execLikeBeforeLogin(target, this.companyInfo.name, type)
        return;
      }

      // ログイン済みの場合、確認ダイアログ、いいね処理開始
      this.commonCompany.startLikeAction(target, this.companyInfo.name, type)

    })
    .catch(err => {
      this.commonCompany.progressViewDetach();
    });
  }

  onLink(url) {
    this.router.navigate([url, this.companyCode]);
  }

  /* API */
  // 企業情報を取得
  private getCompanyInfo() {
    this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
      if (!data) {
        this.common.showToastMessage(Constant.msgErrorNetwork);
        this.showSpinner = false
        return;
      }
      if (!this.companyInfo) {
        this.companyInfo = data;
        this.companyInfo.page = Constant.rpMaterial
      }

      // メインカラー
      this.commonCompany.setCompanyMainColor(this.companyInfo.main_color)

      // ヘッダ用データ
      this.commonCompany.setCompanyHeaderData(this.companyCode, data, Constant.rpMaterial);

      // つながる用データ
      this.tsunagaruData = {
        companyCode: this.companyCode,
        name: this.companyInfo.name,
        display_name: this.companyInfo.display_name,
        isLogin: this.isLogin,
      }

      // 資料取得
      this.getMaterial();
    })
    .catch(err => {
      this.showSpinner = false
    });
  }

  // 資料詳細情報を取得
  private getMaterial() {
    let apiPath = this.apiPath + '/detail';
    const body = {
      companycode: this.companyCode,
      documentcode: this.materialCode,
      cnt: this.showCnt + 1,
      offset: this.offset
    };

    this.common
      .preCheck()
      .then(response => {
        if (this.isLogin) {
          this.common.apiPost(apiPath, body)
            .then(res => {
              this.afterGetData(res);
            })
            .catch(err => {
              this.showSpinner = false;
              this.isDataGetting = false;
              this.common.showToastMessage(Constant.msgErrorNetwork);
            });
        } else {
          apiPath += '/noauth';
          const tempId = localStorage.getItem(Constant.lsTempTalentId)
          if (tempId) {
            body[Constant.apTempTalentId] = tempId
          }
          this.common
          .apiPostBeforeLogin(apiPath, body)
          .then(res => {
            this.afterGetData(res);
          })
          .catch(err => {
            this.showSpinner = false;
            this.isDataGetting = false;
            this.common.showToastMessage(Constant.msgErrorNetwork);
          });
        }
      })
      .catch(err => {
        this.common.debug().log(err);
        this.showSpinner = false;
        this.isDataGetting = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
      });
  }

  // 詳細取得後の処理
  private afterGetData(res) {
    if (!this.otherList) {
      this.otherList = []
    }

    if (res.data) {
      // 他の記事、他の求人取得 初回のみ
      if (this.offset == 0) {
        this.getConentsData()
        this.getJobOfferData()

        if (!res.data.main) {
          this.detailInfo = ''
        }
      }

      if (res.data.main) {
        this.materialShowSpinner = true;
        this.detailInfo = res.data.main

        // 30秒読み込みできなければエラートースト表示
        setTimeout(() => {
          this.pdfError();
        }, Constant.apiTimeoutSecond);

        // SEO設定
        this.setSeoMeta();

        // 限定の場合、noindex
        if (this.detailInfo.limited_flag === 1) {
          this.commonCompany.setNoIndex()
        }

        // PV登録
        this.commonCompany.postEntrypageview(this.companyCode, this.isLogin, Constant.rpMaterial, this.detailInfo.id);
      }

      // 次のデータ有無判断
      if (res.data.list?.length > 0) {
        if (res.data.list.length < this.showCnt + 1) {
          // 指定件数以下
          this.progressRow.endFlg = true;
        } else {
          // 1件多く取得できた場合は次のデータあり。最後のデータを削除
          res.data.list.pop();
          this.progressRow.endFlg = false;
        }
        // 取得したデータをマージする
        this.margeOtherData(res.data.list);
      }

    } else {
      this.detailInfo = '';
    }

    this.showSpinner = false;
    this.isDataGetting = false;
  }

  // 新着記事
  private getConentsData() {
    let apiPath = '/contents/' + this.companyCode;
    const body = {
      release_date: '',
      is_detail: false,
      is_priority: false,
      except_id: 0,
      cnt: 9,
      offset: 0
    };

    if (this.isLogin) {
      // コンテンツを取得
      this.common.apiPost(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    } else {
      apiPath += '/noauth';
      const tempId = localStorage.getItem(Constant.lsTempTalentId)
      if (tempId) {
        body[Constant.apTempTalentId] = tempId
      }
      this.common.apiPostBeforeLogin(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    }
  }
  // コンテンツ取得後の処理
  private afterGetContentData(res) {
    if (res.data.contents.length>0) {
      this.contentsListData = {
        list: res.data.contents,
        companyCode: this.companyCode,
        companyName: this.companyInfo.name,
        isLogin: this.isLogin,
        type: Constant.rpContentsDetail,
      }
      // 企業のロゴとコンテンツの画像を取得する
      this.getContentsImage(res.data.contents);
    }
  }

  // コンテンツ画像取得
  private getContentsImage(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i].image_path === Constant.empty) {
        currentData[i].image_url = ''
        continue;
      }
      // コンテンツの画像を取得する
      const contentsCode = currentData[i].code;
      const apiPath = '/content/' + contentsCode + '/url';
      this.common.apiGetBeforeLogin(apiPath)
        .then(res => {
          if (res.data.url === '' || res.data.url === null) {
            currentData[i].image_url = '';
          } else {
            currentData[i].image_url = res.data.url;
          }
        })
        .catch(err => {
          this.common.debug().log(err);
        });
    }
  }

  // 新着求人取得
  private getJobOfferData() {
    const body = {
      release_date: '',
      data_filter: false,
      is_jobdetail: false,
      is_priority: false,
      except_job_id: 0,
      cnt: 9,
      offset: 0
    };
    this.commonCompany.getJobOfferData(body, this.companyCode, this.companyInfo.name, this.isLogin)
    .then(data => {
      this.jobofferListData = data
    })
    .catch(err => {
    });
  }

  /* private */
  // その他資料をマージ
  private margeOtherData(currentData) {
    if (this.otherList.length > 0) {
      this.otherList.pop();
    }
    this.otherList.push(...currentData);
    this.otherList.push(this.progressRow);
    this.offset = this.otherList.length - 1;
  }

  // ログインチェック
  private checkLogin() {
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        // ログイン済み
        this.isLogin = true;
        if (this.tsunagaruData) {
          this.tsunagaruData.isLogin = this.isLogin
        }
      } else {
        // PV記録のため、仮のID設定
        this.commonCompany.createTempTalentId()
      }

      // 企業情報取得
      this.getCompanyInfo();
    });
  }

  // 初期化処理
  private initialize() {
    this.showSpinner = true
    this.isDataGetting = false;
    this.initializeData();
  }

  private initializeData() {
    this.pdfRealPage = 1
    this.pdfShowPage = 1
    this.pdfTotalPage = 0
    this.pdfErrorFlg = false
    this.pdfViewerWidth = 0
    this.progressRow.code = null
    this.progressRow.endFlg = true
    this.offset = 0
    this.otherList = null
    this.contentsListData = null
    this.jobofferListData = null
    this.pdfSessionId = Date.now().toString()
  }

  // SEOのメタタグ設定
  private setSeoMeta() {
    const title = this.detailInfo.title + ' - ' + this.companyInfo.display_name + 'の資料';
    const description = this.companyInfo.name + Constant.metaDescContents;
    this.commonCompany.setSeoMeta(title, description);
  }

  // PDF関連
 public pdfLoadComplete(pdfData) {
  this.pdfTotalPage = pdfData.numPages;

  // PDFの高さを合わせる
  setTimeout(() => {
    this.resizePdfViewerSize()
    this.materialShowSpinner = false;
  }, 200);
  }

  public prevBtn() {
    if (!this.common.preCheckWindowOpen()) {
      return
    }
    this.pdfBtnDisabled = true
    // PV登録
    this.commonCompany.postDocumentViewTime(
      this.companyInfo.id,
      this.isLogin,
      this.pdfShowPage,
      this.pdfShowPage - 1,
      this.pdfTotalPage,
      this.detailInfo.id,
      this.pdfSessionId)

    this.pdfShowPage--
    if (this.pdfShowPage < this.pdfTotalPage) {
      this.pdfRealPage--;
    }
    setTimeout(() => {
      this.pdfBtnDisabled = false
    }, 100);
  }

  public nextBtn() {
    if (!this.common.preCheckWindowOpen()) {
      return
    }

    this.pdfBtnDisabled = true
    // PV登録
    this.commonCompany.postDocumentViewTime(
      this.companyInfo.id,
      this.isLogin,
      this.pdfShowPage,
      this.pdfShowPage + 1,
      this.pdfTotalPage,
      this.detailInfo.id,
      this.pdfSessionId)

    this.pdfShowPage++
    if (this.pdfShowPage <= this.pdfTotalPage) {
      this.pdfRealPage++;
    }
    setTimeout(() => {
      this.pdfBtnDisabled = false
    }, 100);
  }

  public pdfError() {
    if (this.pdfTotalPage === 0 && !this.pdfErrorFlg) {
      this.common.showToastMessage(Constant.msgErrorNetwork);
      this.pdfErrorFlg = true
    }
    this.materialShowSpinner = false;
  }
  // リサイズ時に高さを合わせる
  pdfRendered() {
    if (this.resetPdfView) {
      this.resizePdfViewerSize()
    }
  }

  // PDFエリアの高さをビューワー内canvasの高さに合わせる
  private resizePdfViewerSize() {
    const canvas = document.querySelector('app-company-material .canvasWrapper canvas');
    const viewer = document.getElementsByClassName('pdf-viewer')  as HTMLCollectionOf<HTMLElement>;
    if (canvas && canvas.clientHeight>0 && canvas.clientWidth != this.pdfViewerWidth) {
      let h = canvas.clientHeight
      if (h >= this.pdfViewerMaxHeight) {
        h = this.pdfViewerMaxHeight
      }
      viewer[0].style.height = h + 'px';
      this.pdfViewerWidth = Math.ceil(canvas.clientWidth)
      this.resetPdfView = false
      return true
    } else {
      return false
    }
  }

  /* いいね、シェア関連 */
  // URLコピー
  onCopy() {
    if (!navigator.clipboard) {
      this.common.showToastMessage(Constant.msgCopyError)
      return
    }

    navigator.clipboard.writeText(location.href).then(
      () => {
        this.common.showToastMessage(Constant.msgSuccessLinkCopy)
      },
      () => {
        this.common.showToastMessage(Constant.msgCopyError)
      })

    // シェア履歴登録
    const shareTypeId = Constant.shareCopyId
    this.commonCompany.postShareHistory(this.companyCode, this.isLogin, Constant.rpMaterial, this.detailInfo.id, shareTypeId)
  }

  // 各SNSへシェアする
  shareSNS(kind: string) {
    let url:URL | undefined;
    let shareTypeId;
    if(Constant.snsX === kind) {
      url = this.createXLinkParam();
      shareTypeId = Constant.shareXId
    }else if(Constant.snsFb === kind) {
      url = this.createFbLinkParam();
      shareTypeId = Constant.shareFbId
    }else if(Constant.snsLinkedin === kind) {
      url = this.createLinkedInParam();
      shareTypeId = Constant.shareLinkedinId
    }else if(Constant.snsLine === kind) {
      url = this.createLineParam();
      shareTypeId = Constant.shareLineId
    }

    // シェア履歴登録
    this.commonCompany.postShareHistory(this.companyCode, this.isLogin, Constant.rpMaterial, this.detailInfo.id, shareTypeId)

    // 別タブを開いて指定URLへ遷移
    if("undefined" != typeof(url)) {
      window.open(url, "_blank", "noreferrer");
    }
  }

  // X
  private createXLinkParam() {
    let url = new URL(this.Constant.snsShareUrlX);
    const text = this.detailInfo.title + ' - ' + this.companyInfo.display_name + 'の資料';
    url.searchParams.append('text',text);
    url.searchParams.append('url',encodeURI(location.href));
    return url;
  }
  // facebook
  private createFbLinkParam() {
    let url = new URL(this.Constant.snsShareUrlFb);
    url.searchParams.append('u',encodeURI(location.href));
    return url;
  }
  // linkedin
  private createLinkedInParam() {
    let url = new URL(this.Constant.snsShareUrlLinkedin);
    url.searchParams.append('url',encodeURI(location.href));
    return url;
  }
  // LINE
  private createLineParam() {
    let url = new URL(this.Constant.snsShareUrlLine);
    url.searchParams.append('url',encodeURI(location.href));
    url.searchParams.append('text',this.detailInfo.title);
    return url;
  }

  // SNSシェアリンクの補足メッセージ
  getSnsShareTooltip(kind) {
    return kind? kind + this.Constant.tooltipMessageSnsShare : this.Constant.tooltipMessageCopy;
  }

  // シェア禁止ダイアログ表示
  disabledShare() {
    const message = sprintf(Constant.msgDisabledShare, 'コンテンツ');
    this.dialog.open(MessageOnlyDialogComponent, {
      width: '330px',
      autoFocus: false,
      data: { message: message, closeFlg: true, blueFlg: true }
    });
  }

  // 他の資料 高さ合わせ
  private equalBoxHeight() {
    const elements = document.getElementsByClassName('materect') as HTMLCollectionOf<HTMLElement>

    // リセット
    for (let i = 0 ; i < elements.length ; i ++ ) {
      elements[i].style.height = 'auto';
    }

    // 高さ調整なし（600px以下）
    if (window.innerWidth <= 600) {
      return;
    }

    const startNo = 0

    for (let i = startNo ; i < elements.length ; i ++ ) {
      let height;
      let showColNum = 2

      if (i % showColNum === startNo) {
        height = elements[i].clientHeight;

        for (let n = i + 1; n <= i + showColNum - 1; n++) {
          if (n >= elements.length) {
            break;
          }
          if (elements[n].clientHeight && height < elements[n].clientHeight) {
              height = elements[n].clientHeight;
          }
        }

        for (let n = i; n <= i + showColNum - 1; n++) {
          if (n >= elements.length) {
            break;
          }
          elements[n].style.height = height + 'px';
        }
      }
    }
  }
}
