export const environment = {
  production: false,
  envName: 'staging',
  version: '1.0.0',
  amplify: {
    Auth: {
      identityPoolId: 'ap-northeast-1:5e20200a-4274-4e43-9a42-6432b8f28835',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_Z7mYNh5Ro',
      userPoolWebClientId: '4nas3pjnishdicltg6tahmsnh2'
    },
    API: {
      endpoints: [
        {
          name: 'talent',
          endpoint: 'https://0dg49u61pb.execute-api.ap-northeast-1.amazonaws.com/stg'
        },
        {
          name: 'admin',
          endpoint: 'https://1z64k3um7j.execute-api.ap-northeast-1.amazonaws.com/prod'
        }
      ]
    },
    Storage: {
      profilePicture: {
        bucket: 'stg.talentpool.talent.profilepicture',
        region: 'ap-northeast-1',
        level: 'private'
      },
      profileFile: {
        bucket: 'stg.talentpool.talent.profilefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
      messageFile: {
        bucket: 'stg.talentpool.common.messagefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
    }
  },
  API_KEY: 'hZAANlQzRMaHIlxQeGQI14gdg3fUTLalaejFduPa',
  API_KEY_ADMIN: '8Cdkh2Ce1h7PSiZv0gSP92oj05fb9kAv3QMO9uLq',
  COLUMN_IMG_URL: 'https://calin.co.jp/assets/column/',
  AnalyticsTrackingId: 'G-PK0FB12WH5'
};
